import React from 'react';
import PropTypes from 'prop-types';
import statisticsLayout from '../constants/statisticsLayout';

function StatisticsHeader({ translate }) {
  return (
    <div className='container-header'>
      <h2>{translate(statisticsLayout.sectionTitle)}</h2>
    </div>
  );
}

StatisticsHeader.propTypes = {
  translate: PropTypes.func.isRequired
};

export default StatisticsHeader;
