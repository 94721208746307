import React from 'react';
import PropTypes from 'prop-types';

function StatisticsItem({ label, value, translate }) {
  return (
    <li className='profile-stat'>
      <p className='text-label'>{translate(label)}</p>
      <p className='text-lead'>{value}</p>
    </li>
  );
}

StatisticsItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

export default StatisticsItem;
