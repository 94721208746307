import { httpService } from 'core-utilities';
import apiConstant from '../constants/apiEndpoint';

const getUserData = userId => {
  return httpService.get(apiConstant.getUserById(userId), {}).then(({ data }) => data);
};

export default {
  getUserData
};
