import { httpService } from 'core-utilities';
import gamesApiConstant from '../constants/apiEndpoint';

const getUserPublicGames = (userId, nextPageCursor) => {
  const params = {
    accessFilter: gamesApiConstant.accessFilter.public,
    cursor: nextPageCursor,
    limit: gamesApiConstant.limitOfNumOfGames
  };
  return httpService.get(gamesApiConstant.getUserGames(userId), params).then(({ data }) => data);
};

export default {
  getUserPublicGames
};
