import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { authenticatedUser } from 'header-scripts';
import StatisticsHeader from '../components/StatisticsHeader';
import StatisticsItem from '../components/StatisticsItem';
import PlaceVisits from '../components/PlaceVisits';
import statisticsLayout from '../constants/statisticsLayout';
import gamesService from '../services/gamesService';
import usersService from '../services/usersService';
import { getUrlUserId } from '../../../utils/appUtil';

function StatisticsContainer({ translate }) {
  const { created, id } = authenticatedUser;
  const { labelOfJoinData, labelOfPlaceVisits } = statisticsLayout;
  const [joinDate, setJoinDate] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [hasGameDataLoaded, setGameDataLoaded] = useState(false);
  const [hasDataFetchTriggered, setDataFetchTriggered] = useState(false);

  const profileUserIdString = getUrlUserId();
  const profileUserId = profileUserIdString ? parseInt(profileUserIdString, 10) : id;
  const isMyProfile = profileUserId === id;

  const setUpJoinData = date => {
    setJoinDate(new Date(date).toLocaleDateString());
  };

  const getJoinedDate = useCallback(() => {
    if (!isMyProfile) {
      usersService
        .getUserData(profileUserId)
        .then(result => {
          if (result?.created) {
            setUpJoinData(result?.created);
          }
        })
        .catch(console.debug);
    } else {
      setUpJoinData(created);
    }
  }, [created, isMyProfile, profileUserId]);

  const fetchNumberOfVisits = useCallback(
    (retrievedGameData, currentPageCursor) => {
      gamesService
        .getUserPublicGames(profileUserId, currentPageCursor)
        .then(
          result => {
            if (result) {
              const { nextPageCursor, data } = result;
              let currentGameData = retrievedGameData;
              currentGameData = currentGameData.concat(data);
              if (nextPageCursor && currentPageCursor !== nextPageCursor) {
                fetchNumberOfVisits(currentGameData, nextPageCursor);
              } else {
                setGameData(currentGameData);
                setGameDataLoaded(true);
              }
            }
          },
          () => {
            // if api failure, still need to sum up current having data
            if (retrievedGameData.length > 0) {
              setGameData(retrievedGameData);
              setGameDataLoaded(true);
            }
          }
        )
        .catch(console.debug);
    },
    [profileUserId]
  );

  const statisticsContainerRef = useRef();
  const validateIfNeedToFetchData = useCallback(() => {
    const statisticsContainerOffset = statisticsContainerRef.current?.getBoundingClientRect().top;
    const windowOffset = window.pageYOffset + window.innerHeight;
    if (windowOffset > statisticsContainerOffset) {
      if (hasDataFetchTriggered) {
        window.removeEventListener('scroll', validateIfNeedToFetchData);
      } else {
        setDataFetchTriggered(true);
        fetchNumberOfVisits([]);
        getJoinedDate();
      }
    }
  }, [fetchNumberOfVisits, hasDataFetchTriggered]);

  useEffect(() => {
    if (!hasDataFetchTriggered) {
      validateIfNeedToFetchData();
      window.addEventListener('scroll', validateIfNeedToFetchData);
    }

    return () => {
      window.removeEventListener('scroll', validateIfNeedToFetchData);
    };
  }, [hasDataFetchTriggered, validateIfNeedToFetchData]);

  return (
    <div className='section profile-statistics' ref={statisticsContainerRef}>
      <StatisticsHeader translate={translate} />
      <div className='section-content'>
        <ul className='profile-stats-container'>
          <StatisticsItem translate={translate} label={labelOfJoinData} value={joinDate} />
          {hasGameDataLoaded && (
            <PlaceVisits translate={translate} label={labelOfPlaceVisits} gameData={gameData} />
          )}
        </ul>
      </div>
    </div>
  );
}

StatisticsContainer.propTypes = {
  translate: PropTypes.func.isRequired
};

export default StatisticsContainer;
