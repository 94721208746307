import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import App from './App';

import '../../../css/profileStatistics/profileStatistics.scss';

const gamesSearchPageId = 'profile-statistics-container';

ready(() => {
  render(<App />, document.getElementById(gamesSearchPageId));
});
