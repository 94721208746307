import { EnvironmentUrls } from 'Roblox';

const { gamesApi, usersApi } = EnvironmentUrls;

export default {
  getUserGames(userId) {
    return {
      url: `${gamesApi}/v2/users/${userId}/games`,
      withCredentials: true
    };
  },
  accessFilter: {
    public: 'Public'
  },
  limitOfNumOfGames: 50,

  getUserById(userId) {
    return {
      url: `${usersApi}/v1/users/${userId}`,
      withCredentials: true
    };
  }
};
