import React from 'react';
import PropTypes from 'prop-types';
import { withTranslations } from 'react-utilities';
import { translationConfig } from './translation.config';
import StatisticsContainer from './containers/StatisticsContainer';

function App({ translate }) {
  return <StatisticsContainer translate={translate} />;
}
App.propTypes = {
  translate: PropTypes.func.isRequired
};
export default withTranslations(App, translationConfig);
