import React from 'react';
import PropTypes from 'prop-types';
import { numberFormat } from 'core-utilities';
import StatisticsItem from './StatisticsItem';

function PlaceVisits({ label, gameData, translate }) {
  let sumNumOfPlaceVisits = 0;
  gameData.forEach(game => {
    sumNumOfPlaceVisits += Number.isNaN(game.placeVisits) ? 0 : game.placeVisits;
  });
  sumNumOfPlaceVisits = numberFormat.getNumberFormat(sumNumOfPlaceVisits);
  return <StatisticsItem translate={translate} label={label} value={sumNumOfPlaceVisits} />;
}

PlaceVisits.propTypes = {
  label: PropTypes.string.isRequired,
  gameData: PropTypes.arrayOf(PropTypes.shape({ placeVisits: PropTypes.number })).isRequired,
  translate: PropTypes.func.isRequired
};

export default PlaceVisits;
